import { ChainId } from "../../constants";

export const EXPLORER_TRANSACTION_URL = {
	[ChainId.MAINNET]: "https://ftmscan.com/tx/",
	[ChainId.ROPSTEN]: "https://ropsten.etherscan.io/tx/",
	[ChainId.Rinkeby]: "https://rinkeby.etherscan.io/tx/",
	[ChainId.Kovan]: "https://kovan.etherscan.io/tx/",
	[ChainId.Ganache]: "https://etherscan.io/tx/",
	[ChainId.FANTOM]: "https://ftmscan.com/tx",
	[ChainId.FANTOM_TESTNET]: "https://testnet.ftmscan.com/tx",
};

export const EXPLORER_URL = {
	[ChainId.Mainnet]: "https://etherscan.io/",
	[ChainId.Ropsten]: "https://ropsten.etherscan.io/",
	[ChainId.Rinkeby]: "https://rinkeby.etherscan.io/",
	[ChainId.Kovan]: "https://kovan.etherscan.io/",
	[ChainId.Ganache]: "https://etherscan.io/",
	[ChainId.FANTOM]: "https://ftmscan.com/",
	[ChainId.FANTOM_TESTNET]: "https://testnet.ftmscan.com/",
};
