import { NETWORK_ID, ChainId } from "../../constants";

const TOKENIZED_REGISTRY_ADDRESS = {
	[ChainId.Mainnet]: "0xD8dc30d298CCf40042991cB4B96A540d8aFFE73a",
	[ChainId.Ropsten]: "0xd03eea21041a19672e451bcbb413ce8be72d0381",
	[ChainId.Kovan]: "0xF1C87dD61BF8a4e21978487e2705D52AA687F97E",
};

const TOKEN_DATA_ADDRESS = {
	[ChainId.Ropsten]: "0xbb2626199c3a71ed15aead2ee1ad90f6c0759077",
};

export const getTokenizedRegistryAddress = () => {
	return `${TOKENIZED_REGISTRY_ADDRESS[NETWORK_ID].toLowerCase()}`;
};

export const getTokenDataAddress = () => {
	return `${TOKEN_DATA_ADDRESS[NETWORK_ID].toLowerCase()}`;
};
