export const routes = {
	instantSwap: {
		title: "exchange",
		path: "/exchange",
		icon: "repeat",
	},
	farm: {
		title: "farm",
		path: "/farm",
		icon: "maximize-2"
	},
	stake: {
		title: "stake",
		path: "/stake",
		icon: "pie-chart",
	},
	governance: {
		title: "governance",
		path: "/governance",
		icon: "list",
	},
	dashboard: {
		title: "dashboard",
		path: "/dashboard",
		icon: "star",
	},
};
