export const routes = {
	instantSwap: {
		title: "exchange",
		path: "/exchange",
		state: "success",
	},
	pools: {
		title: "pools",
		path: "/pools",
		state: "success",
	},
	farm: {
		title: "farm",
		path: "/farm",
		state: "success",
	},
	stake: {
		title: "stake",
		path: "/stake",
		state: "success",
	},
	enchant: {
		title: "enchant",
		path: "/enchant",
		state: "success",
	},
	bridge: {
		title: "bridge",
		path: "/bridge",
		state: "success",
	},
	more: {
		title: "more",
		routes: {
			history: {
				title: "history",
				path: "/history",
				state: "success",
			},
			dashboard: {
				title: "dashboard",
				path: "/dashboard",
				state: "success",
			},
			calculator: {
				title: "calculator",
				path: "/calculator",
				state: "success",
			},
			governance: {
				title: "governance",
				path: "/governance",
				state: "success",
			},
			analytics: {
				title: "analytics",
				path: "/analytics",
				state: "success",
			},
			links: {
				title: "links",
				path: "/links",
				state: "success",
			},
		},
	},
};
