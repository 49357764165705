import { ChainId, Currency, CurrencyAmount, JSBI, Token, TokenAmount, WETH, Pair } from "@uniswap/sdk";
import { useMemo } from "react";
import { STAKING_REWARDS_INTERFACE } from "../../constants/abis/staking-rewards";
import { useActiveWeb3React } from "../../hooks";
import { NEVER_RELOAD, useMultipleContractSingleData } from "../multicall/hooks";
import { parseUnits } from "@ethersproject/units";

export const STAKING_GENESIS = 1600387200; // TODO: UPDATE
export const REWARDS_DURATION_DAYS = 60; // TODO: UPDATE


const DAI_ADDRESS = "0x6B175474E89094C44Da98b954EedeAC495271d0F";
const SOUL_ADDRESS = "0xe2fb177009FF39F52C0134E8007FA0e4BaAcBd07";
const UNI_ADDRESS = "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984";
const USDC_ADDRESS = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
const USDT_ADDRESS = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
const WBTC_ADDRESS = "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599";


export const DAI = new Token(ChainId.MAINNET, DAI_ADDRESS, 18, "DAI", "Dai Stablecoin");
export const SOUL = new Token(ChainId.MAINNET, SOUL_ADDRESS, 18, "SOUL", "SoulPower");
export const UNI = new Token(ChainId.MAINNET, UNI_ADDRESS, 18, "UNI", "Uniswap");
export const USDC = new Token(ChainId.MAINNET, USDC_ADDRESS , 6, "USDC", "USD//C");
export const USDT = new Token(ChainId.MAINNET, USDT_ADDRESS, 6, "USDT", "Tether USD");
export const WBTC = new Token(ChainId.MAINNET, WBTC_ADDRESS, 18, "WBTC", "Wrapped BTC");

// try to parse a user entered amount for a given token
export function tryParseAmount(value?: string, currency?: Currency): CurrencyAmount | undefined {
	if (!value || !currency) {
		return undefined;
	}
	try {
		const typedValueParsed = parseUnits(value, currency.decimals).toString();
		if (typedValueParsed !== "0") {
			return currency instanceof Token
				? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
				: CurrencyAmount.ether(JSBI.BigInt(typedValueParsed));
		}
	} catch (error) {
		// should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
		console.debug(`Failed to parse input amount: "${value}"`, error);
	}
	// necessary for all paths to return a value
	return undefined;
}

// TODO add staking rewards addresses here
export const STAKING_REWARDS_INFO: {
	[chainId in ChainId]?: {
		tokens: [Token, Token];
		stakingRewardAddress: string;
	}[];
} = {
	[ChainId.MAINNET]: [ // TODO: UPDATE ADDRESSESS
		{
			tokens: [WETH[ChainId.MAINNET], DAI],
			stakingRewardAddress: "0xa1484C3aa22a66C62b77E0AE78E15258bd0cB711",
		},
		{
			tokens: [WETH[ChainId.MAINNET], USDC],
			stakingRewardAddress: "0x7FBa4B8Dc5E7616e59622806932DBea72537A56b",
		},
		{
			tokens: [WETH[ChainId.MAINNET], USDT],
			stakingRewardAddress: "0x6C3e4cb2E96B01F4b866965A91ed4437839A121a",
		},
		{
			tokens: [WETH[ChainId.MAINNET], WBTC],
			stakingRewardAddress: "0xCA35e32e7926b96A9988f61d510E038108d8068e",
		},
	],
};

export interface StakingInfo {
	// the address of the reward contract
	stakingRewardAddress: string;
	// the tokens involved in this pair
	tokens: [Token, Token];
	// the amount of token currently staked, or undefined if no account
	stakedAmount: TokenAmount;
	// the amount of reward token earned by the active account, or undefined if no account
	earnedAmount: TokenAmount;
	// the total amount of token staked in the contract
	totalStakedAmount: TokenAmount;
	// the amount of token distributed per second to all LPs, constant
	totalRewardRate: TokenAmount;
	// the current amount of token distributed to the active account per second.
	// equivalent to percent of total supply * reward rate
	rewardRate: TokenAmount;
	// when the period ends
	periodFinish: Date | undefined;
	// calculates a hypothetical amount of token distributed to the active account per second.
	getHypotheticalRewardRate: (
		stakedAmount: TokenAmount,
		totalStakedAmount: TokenAmount,
		totalRewardRate: TokenAmount
	) => TokenAmount;
}

// gets the staking info from the network for the active chain id
export function useStakingInfo(pairToFilterBy?: Pair | null): StakingInfo[] {
	const { chainId, account } = useActiveWeb3React();

	const info = useMemo(
		() =>
			chainId
				? STAKING_REWARDS_INFO[chainId]?.filter((stakingRewardInfo) =>
						pairToFilterBy === undefined
							? true
							: pairToFilterBy === null
							? false
							: pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
							  pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
				  ) ?? []
				: [],
		[chainId, pairToFilterBy]
	);

	const uni = chainId ? SOUL_ADDRESS[chainId] : undefined;

	const rewardsAddresses = useMemo(() => info.map(({ stakingRewardAddress }) => stakingRewardAddress), [info]);

	const accountArg = useMemo(() => [account ?? undefined], [account]);

	// get all the info from the staking rewards contracts
	const balances = useMultipleContractSingleData(
		rewardsAddresses,
		STAKING_REWARDS_INTERFACE,
		"balanceOf",
		accountArg
	);
	const earnedAmounts = useMultipleContractSingleData(
		rewardsAddresses,
		STAKING_REWARDS_INTERFACE,
		"earned",
		accountArg
	);
	const totalSupplies = useMultipleContractSingleData(rewardsAddresses, STAKING_REWARDS_INTERFACE, "totalSupply");

	// tokens per second, constants
	const rewardRates = useMultipleContractSingleData(
		rewardsAddresses,
		STAKING_REWARDS_INTERFACE,
		"rewardRate",
		undefined,
		NEVER_RELOAD
	);
	const periodFinishes = useMultipleContractSingleData(
		rewardsAddresses,
		STAKING_REWARDS_INTERFACE,
		"periodFinish",
		undefined,
		NEVER_RELOAD
	);

	return useMemo(() => {
		if (!chainId || !uni) return [];

		return rewardsAddresses.reduce<StakingInfo[]>((memo, rewardsAddress, index) => {
			// these two are dependent on account
			const balanceState = balances[index];
			const earnedAmountState = earnedAmounts[index];

			// these get fetched regardless of account
			const totalSupplyState = totalSupplies[index];
			const rewardRateState = rewardRates[index];
			const periodFinishState = periodFinishes[index];

			if (
				// these may be undefined if not logged in
				!balanceState?.loading &&
				!earnedAmountState?.loading &&
				// always need these
				totalSupplyState &&
				!totalSupplyState.loading &&
				rewardRateState &&
				!rewardRateState.loading &&
				periodFinishState &&
				!periodFinishState.loading
			) {
				if (
					balanceState?.error ||
					earnedAmountState?.error ||
					totalSupplyState.error ||
					rewardRateState.error ||
					periodFinishState.error
				) {
					console.error("Failed to load staking rewards info");
					return memo;
				}

				// get the LP token
				const tokens = info[index].tokens;
				const dummyPair = new Pair(new TokenAmount(tokens[0], "0"), new TokenAmount(tokens[1], "0"));

				// check for account, if no account set to 0

				const stakedAmount = new TokenAmount(
					dummyPair.liquidityToken,
					JSBI.BigInt(balanceState?.result?.[0] ?? 0)
				);
				const totalStakedAmount = new TokenAmount(
					dummyPair.liquidityToken,
					JSBI.BigInt(totalSupplyState.result?.[0])
				);
				const totalRewardRate = new TokenAmount(SOUL, JSBI.BigInt(rewardRateState.result?.[0]));

				const getHypotheticalRewardRate = (
					stakedAmount: TokenAmount,
					totalStakedAmount: TokenAmount,
					totalRewardRate: TokenAmount
				): TokenAmount => {
					return new TokenAmount(
						SOUL,
						JSBI.greaterThan(totalStakedAmount.raw, JSBI.BigInt(0))
							? JSBI.divide(JSBI.multiply(totalRewardRate.raw, stakedAmount.raw), totalStakedAmount.raw)
							: JSBI.BigInt(0)
					);
				};

				const individualRewardRate = getHypotheticalRewardRate(
					stakedAmount,
					totalStakedAmount,
					totalRewardRate
				);

				const periodFinishMs = periodFinishState.result?.[0]?.mul(1000)?.toNumber();

				memo.push({
					stakingRewardAddress: rewardsAddress,
					tokens: info[index].tokens,
					periodFinish: periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
					earnedAmount: new TokenAmount(SOUL, JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)),
					rewardRate: individualRewardRate,
					totalRewardRate: totalRewardRate,
					stakedAmount: stakedAmount,
					totalStakedAmount: totalStakedAmount,
					getHypotheticalRewardRate,
				});
			}
			return memo;
		}, []);
	}, [balances, chainId, earnedAmounts, info, periodFinishes, rewardRates, rewardsAddresses, totalSupplies, uni]);
}

export function useTotalUniEarned(): TokenAmount | undefined {
	const { chainId } = useActiveWeb3React();
	const uni = chainId ? SOUL_ADDRESS[chainId] : undefined;
	const stakingInfos = useStakingInfo();

	return useMemo(() => {
		if (!uni) return undefined;
		return (
			stakingInfos?.reduce(
				(accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
				new TokenAmount(SOUL, "0")
			) ?? new TokenAmount(SOUL, "0")
		);
	}, [stakingInfos, uni]);
}

// based on typed value
export function useDerivedStakeInfo(
	typedValue: string,
	stakingToken: Token,
	userLiquidityUnstaked: TokenAmount | undefined
): {
	parsedAmount?: CurrencyAmount;
	error?: string;
} {
	const { account } = useActiveWeb3React();

	const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingToken);

	const parsedAmount =
		parsedInput && userLiquidityUnstaked && JSBI.lessThanOrEqual(parsedInput.raw, userLiquidityUnstaked.raw)
			? parsedInput
			: undefined;

	let error: string | undefined;
	if (!account) {
		error = "Connect Wallet";
	}
	if (!parsedAmount) {
		error = error ?? "Enter an amount";
	}

	return {
		parsedAmount,
		error,
	};
}

// based on typed value
export function useDerivedUnstakeInfo(
	typedValue: string,
	stakingAmount: TokenAmount
): {
	parsedAmount?: CurrencyAmount;
	error?: string;
} {
	const { account } = useActiveWeb3React();

	const parsedInput: CurrencyAmount | undefined = tryParseAmount(typedValue, stakingAmount.token);

	const parsedAmount =
		parsedInput && JSBI.lessThanOrEqual(parsedInput.raw, stakingAmount.raw) ? parsedInput : undefined;

	let error: string | undefined;
	if (!account) {
		error = "Connect Wallet";
	}
	if (!parsedAmount) {
		error = error ?? "Enter an amount";
	}

	return {
		parsedAmount,
		error,
	};
}
