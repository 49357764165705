const ethereum = {
	path: "m/44'/60'/0'/0",
	label: "Ethereum",
};
const poaNetwork = {
	path: "m/44'/60'/0'/0",
	label: "POA network",
};
const ethereumClassic = {
	path: "m/44'/61'/0'/0",
	label: "Ethereum Classic",
};
const ropsten = {
	path: "m/44'/1'/0'/0",
	label: "Ropsten Testnet",
};
const singularDTV = {
	path: "m/0'/0'/0'",
	label: "SingularDTV",
};
const expanse = {
	path: "m/44'/40'/0'/0",
	label: "Expanse",
};
const ubiq = {
	path: "m/44'/108'/0'/0",
	label: "Ubiq",
};
const ellaism = {
	path: "m/44'/163'/0'/0",
	label: "Ellaism",
};
const etherGem = {
	path: "m/44'/1987'/0'/0",
	label: "EtherGem",
};
const callisto = {
	path: "m/44'/820'/0'/0",
	label: "Callisto",
};
const ethereumSocial = {
	path: "m/44'/1128'/0'/0",
	label: "Ethereum Social",
};
const musicoin = {
	path: "m/44'/184'/0'/0",
	label: "Musicoin",
};
const goChain = {
	path: "m/44'/6060'/0'/0",
	label: "GoChain",
};
const eosClassic = {
	path: "m/44'/2018'/0'/0",
	label: "EOS Classic",
};
const akroma = {
	path: "m/44'/200625'/0'/0",
	label: "Akroma",
};
const etherSocialNetwork = {
	path: "m/44'/31102'/0'/0",
	label: "EtherSocial Network",
};
const pirl = {
	path: "m/44'/164'/0'/0",
	label: "PIRL",
};
const ether1 = {
	path: "m/44'/1313114'/0'/0",
	label: "Ether-1",
};
const atheios = {
	path: "m/44'/1620'/0'/0",
	label: "Atheios",
};

const tomoChain = {
	path: "m/44'/889'/0'/0",
	label: "TomoChain",
};
const mixBlockchain = {
	path: "m/44'/76'/0'/0",
	label: "Mix Blockchain",
};
const iolite = {
	path: "m/44'/1171337'/0'/0",
	label: "Iolite",
};
const ledgerEthereum = {
	path: "m/44'/60'/0'",
	label: "Ethereum",
};
const ledgerLiveEthereum = {
	path: "m/44'/60'",
	label: "Ethereum - Ledger Live",
};
const ledgerEthereumClassic = {
	path: "m/44'/60'/160720'/0'",
	label: "Ethereum Classic",
};
const ledgerLiveEthereumClassic = {
	path: "m/44'/61'",
	label: "Ethereum Classic - Ledger Live",
};
const rskMainnet = {
	path: "m/44'/137'/0'/0",
	label: "RSK Mainnet",
};
const rskTestnet = {
	path: "m/44'/37310'/0'/0",
	label: "RSK Testnet",
};
const keepkeyEthereum = {
	path: "m/44'/60'",
	label: "Ethereum",
};
const keepkeyEthereumClassic = {
	path: "m/44'/61'",
	label: "Ethereum Classic",
};
const thundercore = {
	path: "m/44'/1001'/0'/0",
	label: "ThunderCore",
};
const solidum = {
	path: "m/44'/997'/0'/0",
	label: "Solidum",
};
const metadium = {
	path: "m/44'/916'/0'/0",
	label: "Metadium",
};
const reoscChain = {
	path: "m/44'/2894'/0'/0",
	label: "REOSC",
};
const dexon = {
	path: "m/44'/237'/0'/0",
	label: "DEXON Network",
};
const lightstreamsNetwork = {
	path: "m/44'/60'/0'",
	label: "Lightstreams Network",
};
const mintmeComCoin = {
	path: "m/44'/227'/0'/0",
	label: "MintMe.com Coin",
};
const ethercore = {
	path: "m/44'/466'/0'/0",
	label: "EtherCore",
};
export {
	ethereum,
	ethereumClassic,
	ledgerEthereum,
	ledgerLiveEthereum,
	ledgerEthereumClassic,
	ledgerLiveEthereumClassic,
	ropsten,
	singularDTV,
	expanse,
	ubiq,
	ellaism,
	etherGem,
	callisto,
	ethereumSocial,
	musicoin,
	goChain,
	eosClassic,
	akroma,
	etherSocialNetwork,
	pirl,
	ether1,
	atheios,
	tomoChain,
	mixBlockchain,
	iolite,
	rskMainnet,
	rskTestnet,
	keepkeyEthereum,
	keepkeyEthereumClassic,
	thundercore,
	solidum,
	metadium,
	poaNetwork,
	reoscChain,
	dexon,
	lightstreamsNetwork,
	mintmeComCoin,
	ethercore,
};
